<template>
    <el-dialog :visible="visible" :title="title" @close="handleCancel" :close-on-click-modal="false" width="600px">
        <el-form :model="form" :inline="false" label-position="right" label-width="60px" @submit.native.prevent>
            <el-form-item label="货号：">
                <el-autocomplete placeholder="请输入货号" ref="queryItemNo" v-model="form.itemNo" :trigger-on-focus="false"
                    value-key="ItemNo" :fetch-suggestions="querySearchItemNo" @change="handleMatchItemNo()"
                    @select="handleSeletedItemNo($event)" :disabled="!fuzzySearch" style="width:95%;"></el-autocomplete>
            </el-form-item>
        </el-form>
        <div class="div-spec-info" v-if="details.length > 0">
            <div class="div-check-all">
                <el-checkbox v-model="checkAll" :disabled="disabledAll" @change="handleCheckAll">全选</el-checkbox>
            </div>
            <div class="div-check-item" v-for="(item, idx) in details" :key="item.ProductID + '_' + idx">
                <div class="div-check-item-color" :title="item.Color" v-if="item.Color">
                    <el-checkbox v-model="item.Checked" :disabled="item.Disabled" @change="handleColorCheck(item)">{{
                        item.Color }}</el-checkbox>
                </div>
                <div class="div-check-item-size" v-if="item.Details.length > 0">
                    <el-checkbox v-model="size.Checked" :disabled="size.Disabled" @change="handleSizeCheck(item, size)"
                        v-for="size in item.Details" :label="size.Size" :key="item.ProductID + '-' + size.SpecID">
                        {{ size.Size }}
                        <template v-if="showNumBox">
                            <el-input-number @focus="handleSelectText($event)" :disabled="!size.Checked" :min="0"
                                :max="getNoCheckedNumTotal(size.SpecID)" v-model="size.Num" :controls="false"
                                @change="handleSizeNumChange" style="width:40px;"></el-input-number>
                        </template>
                    </el-checkbox>
                </div>
            </div>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button @click="handleCancel">取消</el-button>
            <el-button type="primary" @click="handleSave(true)">创建后继续新增</el-button>
            <el-button type="primary" @click="handleSave(false)">创建</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { submit } from "@/api/base";

export default {
    name: "AddGoods",
    data() {
        return {
            visible: false,
            getStock: false,
            fuzzySearch: true,
            title: '',
            checkAll: true,
            disabledAll: false,
            searchItems: [],
            timeout: null,
            details: [],
            warehouseId: '',
            form: {
                itemNo: "",
                productId: 0,
                details: {},
            },
            showNumBox: false,
            maxNum: 0,
        };
    },
    methods: {
        init(isGetStock, title, initItemNo, warehouseId, showNumBox, maxNum) {
            var that = this;
            that.getStock = isGetStock;
            that.title = title;
            if (typeof (warehouseId) != 'undefined') {
                that.warehouseId = warehouseId
            }
            if (typeof (initItemNo) != 'undefined') {
                that.form.itemNo = initItemNo;
                that.fuzzySearch = false;
                that.checkAll = that.fuzzySearch;
                that.querySearchItemNo(initItemNo, that.handleMatchItemNo);
            }
            if (typeof (showNumBox) != 'undefined' && showNumBox) {
                that.showNumBox = true;
                if (typeof (maxNum) != 'undefined' && maxNum > 0) {
                    that.maxNum = maxNum;
                }
            }
            that.visible = true;
        },
        handleSelectText(e) {
            e.currentTarget.select();
        },
        handleCheckAll() {
            var that = this;
            for (var i = 0; i < that.details.length; i++) {
                that.details[i].Checked = that.checkAll;
                for (var j = 0; j < that.details[i].Details.length; j++) {
                    that.details[i].Details[j].Checked = that.checkAll;
                }
            }
        },
        handleColorCheck(colorItem) {
            for (var j = 0; j < colorItem.Details.length; j++) {
                colorItem.Details[j].Checked = colorItem.Checked;
            }
        },
        handleSizeNumChange() {
            var that = this;
            if (that.maxNum > 0) {
                var allCheckTotal = that.getAllCheckedNumTotal();
                that.$nextTick(() => {
                    for (var i = 0; i < that.details.length; i++) {
                        for (var j = 0; j < that.details[i].Details.length; j++) {
                            let size = that.details[i].Details[j];
                            size.Disabled = allCheckTotal >= that.maxNum && size.Num <= 0;
                        }
                        that.details[i].Disabled = allCheckTotal >= that.maxNum;
                    }
                    this.disabledAll = allCheckTotal >= that.maxNum;
                    this.$forceUpdate();
                });
            }
        },
        getNoCheckedNumTotal(specId) {
            var that = this;
            if (that.maxNum <= 0) {
                return Infinity;
            }
            var total = that.maxNum;
            for (var i = 0; i < that.details.length; i++) {
                for (var j = 0; j < that.details[i].Details.length; j++) {
                    let size = that.details[i].Details[j];
                    if (typeof (specId) != 'undefined' && specId == size.SpecID) {
                        continue;
                    }
                    let isChecked = size.Checked;
                    let num = size.Num;
                    if (isChecked && typeof (num) != 'undefined' && num > 0) {
                        total -= num;
                    }
                }
            }
            return total;
        },
        getAllCheckedNumTotal(specId) {
            var that = this;
            if (that.maxNum <= 0) {
                return Infinity;
            }
            var allCheckTotal = 0;
            for (var i = 0; i < that.details.length; i++) {
                for (var j = 0; j < that.details[i].Details.length; j++) {
                    let size = that.details[i].Details[j];
                    if (typeof (specId) != 'undefined' && specId == size.SpecID) {
                        continue;
                    }
                    let isChecked = size.Checked;
                    let num = size.Num;
                    if (isChecked && typeof (num) != 'undefined' && num > 0) {
                        allCheckTotal += num;
                    }
                }
            }
            return allCheckTotal;
        },
        handleSizeCheck(colorItem, sizeItem) {
            var that = this;
            if (!sizeItem.Checked) {
                colorItem.Checked = sizeItem.Checked;
                if (that.maxNum > 0) {
                    that.$nextTick(() => {
                        sizeItem.Num = 0;
                        that.handleSizeNumChange();
                        that.$forceUpdate();
                    });
                }
            } else {
                this.$nextTick(async () => {
                    if (that.maxNum > 0) {
                        var allCheckTotal = that.getAllCheckedNumTotal();
                        if (allCheckTotal < that.maxNum) {
                            sizeItem.Num = 1;
                        } else {
                            sizeItem.Num = 0;
                        }
                        this.handleSizeNumChange();
                    }
                    var allChecked = true;
                    for (var j = 0; j < colorItem.Details.length; j++) {
                        if (!colorItem.Details[j].Checked) {
                            allChecked = false;
                            break;
                        }
                    }
                    colorItem.Checked = allChecked;
                    this.$forceUpdate();
                });
            }
        },
        handleSeletedItemNo(selectedRow) {
            this.form.productId = selectedRow.ProductID;
            this.details = [];
            var that = this;
            var specs = selectedRow.Specs;
            if (specs.length > 0) {
                let tempObj = {};
                for (var i = 0; i < specs.length; i++) {
                    specs[i].Checked = that.fuzzySearch;
                    specs[i].Disabled = false;
                    specs[i].Num = 0;
                    if (!tempObj[specs[i].Color]) {
                        tempObj[specs[i].Color] = {
                            ProductID: selectedRow.ProductID,
                            ItemNo: selectedRow.ItemNo,
                            DefaultSupplierName: selectedRow.DefaultSupplierName,
                            Color: specs[i].Color,
                            Checked: that.fuzzySearch,
                            Disabled: false,
                            Details: []
                        };
                    }
                    tempObj[specs[i].Color].Details.push(specs[i]);
                }
                this.details = Object.values(tempObj);
            }
        },
        handleMatchItemNo() {
            var that = this;
            let selectedRow = that.searchItems.filter((i) => i.ItemNo.toLowerCase() == that.form.itemNo.toLowerCase());
            if (selectedRow.length > 0) {
                that.handleSeletedItemNo(selectedRow[0]);
            } else {
                that.details = [];
            }
        },
        async querySearchItemNo(query, callback) {
            var that = this;
            if (query !== '') {
                clearTimeout(that.timeout);
                that.timeout = setTimeout(async () => {//加上延时，避免段时间输入太多而请求太多次
                    const { data } = await submit("/api/purchase/getProducts", {
                        Keyword: query, GetSpecs: true, GetStock: that.getStock, FuzzySearch: that.fuzzySearch, WIDs: that.warehouseId
                    });
                    that.searchItems = data;
                    if (callback != null) {
                        callback(data);
                    }
                }, 200);
            }
        },
        async handleSave(isContinue) {
            var that = this;
            if (that.details.length > 0) {
                //本次选中商品
                that.form.details = {
                    ProductID: that.form.productId,
                    ItemNo: that.form.itemNo,
                    DefaultSupplierName: '',
                    Colors: {}
                };
                for (var i = 0; i < that.details.length; i++) {
                    var colorItem = that.details[i];
                    if (i == 0) {
                        that.form.details.DefaultSupplierName = colorItem.DefaultSupplierName;
                    }
                    var resColor = { ProductID: that.form.productId, ItemNo: that.form.itemNo, Color: colorItem.Color, Details: [] };
                    var hasSize = false;
                    for (var j = 0; j < colorItem.Details.length; j++) {
                        var sizeItem = colorItem.Details[j];
                        if (!sizeItem.Checked) continue;
                        hasSize = true;
                        var resSizeDetail = { ProductID: sizeItem.ProductID, SpecID: sizeItem.SpecID, ItemNo: sizeItem.ItemNo, Color: sizeItem.Color, Size: sizeItem.Size };
                        if (that.getStock) {
                            resSizeDetail.Stock = sizeItem.Stock;
                        }
                        if (that.showNumBox) {
                            resSizeDetail.Num = sizeItem.Num;
                        }
                        resColor.Details.push(resSizeDetail);
                    }
                    if (hasSize) {
                        that.form.details.Colors[colorItem.Color] = resColor;
                    }
                }
                var colors = Object.keys(that.form.details.Colors);
                if (colors.length <= 0) {
                    that.$message({
                        type: "error",
                        message: "请选择要商品规格!",
                    });
                    return;
                }
            } else {
                that.$message({
                    type: "error",
                    message: "请选择要创建的商品!",
                });
                return;
            }
            that.$emit('handleSave', that.form.details);
            that.clearData();
            if (that.fuzzySearch) {
                that.$message({
                    type: "success",
                    message: "操作成功!",
                });
            }
            if (!isContinue) {
                that.handleCancel();
            } else {
                that.$refs.queryItemNo.focus();
            }
        },
        handleCancel() {
            this.clearData();
            this.searchItems = [];
            this.getStock = false;
            this.visible = false;
        },
        clearData() {
            this.form = {
                itemNo: "",
                productId: 0,
                details: {},
            };
            this.details = [];
            this.showNumBox = false;
            this.maxNum = 0;
        }
    },
};
</script>

<style lang="scss" scoped>
.dialog-footer {
    position: relative;

    .checkbox-block {
        position: absolute;
        left: 28px;
        top: 8px;
    }
}

.el-form-item__content {
    margin-left: 0px;
}

.div-spec-info {
    padding: 10px 0px 0px 7px;

    .div-check-item {
        margin-top: 5px;
        margin-left: 15px;
        display: flex;

        .el-checkbox {
            margin-right: 10px;
        }

        .div-check-item-color {
            float: left;
            width: 20%;
            overflow: hidden;
        }

        .div-check-item-size {
            float: left;
            width: 80%;
            overflow: hidden;
        }
    }
}

::v-deep {
    .el-checkbox__label {
        .el-input-number.is-without-controls .el-input__inner {
            padding-left: 5px;
            padding-right: 5px;
        }

        .el-input--small .el-input__inner {
            height: 22px;
            line-height: 22px;
        }
    }
}
</style>