<template>
  <el-container>
    <el-aside width="613px">
      <el-container>
        <el-header height="85px">
          <el-form :inline="true">
            <el-form-item label="时间：">
              <el-date-picker v-model="queryParam.dateRange" type="daterange" start-placeholder="开始日期"
                end-placeholder="结束日期" value-format="yyyy-MM-dd" class="date-range" range-separator="至" :editable="false"
                :clearable="true"></el-date-picker>
            </el-form-item>
            <el-form-item label="状态：">
              <el-select v-model="queryParam.CheckFlag" :clearable="true">
                <el-option value="0" label="待确认"></el-option>
                <el-option value="1" label="生效"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="仓库：" v-if="$store.state.routes.systemSetting.MultiWarehouse">
              <warehouse-select :value.sync="queryParam.WID" select_style="width:125px;"></warehouse-select>
            </el-form-item>
            <el-form-item style="float: right">
              <el-button type="primary" @click="handleQuery">查询</el-button>
              <el-button type="default" @click="reset()">重置</el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleAdd" :loading="addLoading">创建盘点任务</el-button>
            </el-form-item>
            <el-form-item>
              <el-dropdown @command="handleExportAll">
                <el-button type="primary" plain>
                  全盘盘点<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="Export">导出全部商品库存</el-dropdown-item>
                  <el-dropdown-item command="Import">导入并创建盘点单</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-upload style="display:none;" ref="importXls" accept=".xls,.xlxs" :action="xlsData.uploadUrl"
                :show-file-list="false" class="div-upload" :headers="xlsData.headers" :multiple="false"
                :on-success="uploadXlsSuccess" :on-error="uploadXlsError" :before-upload="uploadXlsBefore" />
            </el-form-item>

            <el-form-item>
              <el-dropdown @command="handleExportOther">
                <el-button type="primary" plain>
                  其他盘点<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="Export">下载导出模版</el-dropdown-item>
                  <el-dropdown-item command="Import">导入并创建盘点单</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-upload style="display:none;" ref="importOtherXls" accept=".xls,.xlxs" :action="xlsData.uploadOtherUrl"
                :show-file-list="false" class="div-upload" :headers="xlsData.headers" :multiple="false"
                :on-success="uploadXlsSuccess" :on-error="uploadXlsError" :before-upload="uploadXlsBefore" />
            </el-form-item>

          </el-form>
        </el-header>
        <el-main>
          <grid-table ref="gridTable" :showIndexColumn="false" :showLastEmptyColumn="false" :showPagination="true"
            :tableConfig="tableConfig" @selectionChange="handleSelectionChange" @handleRowClick="handleRowClick"
            :resizable="false">
          </grid-table>
        </el-main>
      </el-container>
    </el-aside>
    <el-main>
      <el-container>
        <el-header height="40px">
          <span class="span-spec-name">盘点日期：{{ calcTotalCount('time') }}</span>
          <span class="span-spec-count" v-if="$store.state.routes.systemSetting.MultiWarehouse">
            仓库：
            <template v-if="checkRight('check')">
              <warehouse-select :value.sync="detail.row.WID" select_style="width:150px;" :set_default="true"
                @handleChanged="handleWarehouseUpdated"></warehouse-select>
            </template>
            <template v-else>
              {{ detail.row.WName ? detail.row.WName : '默认仓库' }}
            </template>
          </span>
          <span class="span-spec-count">盘点前合计：{{ calcTotalCount('OldStock') }}</span>
          <span class="span-spec-count">盘点后合计：{{ calcTotalCount('NewStock') }}</span>
          <span class="span-spec-count">盈亏合计：{{ calcTotalCount('AddStock') }}</span>
        </el-header>
        <el-main>
          <el-table ref="detailTable" :data="detail.rowDetails" v-loading="detail.loading" border :header-cell-style="{
            'background-color': '#E5EEF6',
            'border-color': 'rgba(218, 223, 228, 1)',
            height: '48px',
          }" height="100%">
            <el-table-column label="序号" width="80px" align="center">
              <template slot-scope="{ row,$index }">
                <template v-if="row._NewRow && checkRight('newDetail')">
                  <el-button type="text" @click="addNewDetails(row)">新增</el-button>
                </template>
                <template v-else>
                  {{ checkRight('newDetail') ? $index : ($index + 1) }}
                </template>
              </template>
            </el-table-column>
            <el-table-column prop="ItemNo" label="货号" header-align="center" align="center" width="235" :resizable="false">
              <template slot-scope="{ row,$index }">
                <template v-if="row._NewRow && checkRight('newDetail')">
                  <el-autocomplete placeholder="请输入货号" v-model="row.ItemNo" :trigger-on-focus="false" value-key="ItemNo"
                    :fetch-suggestions="querySearchItemNo" @change="handleMatchItemNo(row)" :clearable="true"
                    @select="handleSeletedItemNo($event, row)" style="width:95%;"></el-autocomplete>
                </template>
                <template v-else>
                  {{ row.ItemNo }}
                  <span class="span-copy" v-if="checkRight('copy')" @click="handleCopyDetail(row)">复制</span>
                  <el-popconfirm v-if="checkRight('deleteDetail')" confirm-button-text='好的' cancel-button-text='不用了'
                    icon="el-icon-info" icon-color="red" title="确定删除吗？" @confirm="deleteDetail($index)">
                    <span class="span-delete" slot="reference">删除</span>
                  </el-popconfirm>

                </template>
              </template>
            </el-table-column>
            <el-table-column prop="SpecName" label="颜色及规格" header-align="center" align="center" width="200"
              :resizable="false">
              <template slot-scope="{ row }">
                <template v-if="row._NewRow && checkRight('newDetail')">
                  <el-select v-model="row.SpecID" style="width: 90%" placeholder="请选择规格" :clearable="true">
                    <el-option v-if="newDetails.length > 0" value="-1" label="全部"></el-option>
                    <el-option v-for="(newItem, index) in newDetails" :key="index" :value="newItem.SpecID"
                      :label="newItem.SpecName"></el-option>
                  </el-select>
                </template>
                <template v-else>
                  {{ row.SpecName }}
                </template>
              </template>
            </el-table-column>
            <el-table-column prop="OldStock" label="盘点前库存" header-align="center" align="center" width="200"
              :resizable="false">
              <template slot-scope="{ row }">
                <template v-if="row._NewRow && checkRight('newDetail')">
                  -
                </template>
                <template v-else>
                  {{ row.OldStock }}
                </template>
              </template>
            </el-table-column>
            <el-table-column prop="NewStock" label="盘点后库存" header-align="center" align="center" width="200"
              :resizable="false">
              <template slot="header">
                <span style="line-height: 30px;">盘点后库存</span>
                <el-button type="primary" v-if="checkRight('newDetail')" @click="batchSetNum"
                  style="float:right;">盘前库存</el-button>
              </template>
              <template slot-scope="{ row }">
                <template v-if="row._NewRow && checkRight('newDetail')">
                  <el-input-number style="width: 80px" :controls="false" :min="0" v-model="row.NewStock"
                    @focus="handleSelectText($event)"></el-input-number>
                </template>
                <template v-else>
                  <template v-if="checkRight('editDetailStock')">
                    <el-input-number style="width: 80px" :controls="false" :min="0" v-model="row.NewStock"
                      @focus="handleSelectText($event)" @change="handleDetailStockChange(row)"></el-input-number>
                  </template>
                  <template v-else>
                    {{ row.NewStock }}
                  </template>
                </template>
              </template>
            </el-table-column>
            <el-table-column prop="AddStockStr" label="盈亏库存" header-align="center" align="center" width="180"
              :resizable="false">
              <template slot-scope="{ row }">
                <template v-if="row._NewRow && checkRight('newDetail')">
                  -
                </template>
                <template v-else>
                  {{ calcDetail(row) }}
                </template>
              </template>
            </el-table-column>
          </el-table>
        </el-main>
        <el-footer height="40px" style="line-height: 40px;">
          <div>
            <el-button type="primary" v-if="checkRight('newDetail')" @click="showAddGoodDlg()">新增盘点商品</el-button>
            <el-button type="danger" v-if="checkRight('delete')" @click="deleteRow"
              :loading="deleteLoading">删除盘点单</el-button>
            <el-button type="success" v-if="checkRight('save')" @click="handleSave('save')"
              :loading="saveLoading">修改保存</el-button>
            <el-button type="primary" v-if="checkRight('check')" @click="handleSave('check')"
              :loading="checkLoading">确认生效</el-button>
          </div>
          <div>
            <el-upload ref="uploadXls" accept=".xls,.xlxs"
              :action="xlsData.uploadUrl + '?InventoryID=' + (detail.row == null ? 0 : detail.row.InventoryID)"
              :show-file-list="false" class="div-upload" :headers="xlsData.headers" :on-success="uploadXlsSuccess"
              :multiple="false" :on-error="uploadXlsError" :before-upload="uploadXlsBefore" v-if="checkRight('import')">
              <el-button type="default" plain>导入盘点后库存</el-button>
            </el-upload>
            <el-button type="default" v-if="checkRight('export')" @click="handleExport">导出盘点单</el-button>
            <el-button type="default" v-if="checkRight('print')" @click="handlePrint">打印盘点单</el-button>
          </div>
        </el-footer>
      </el-container>
    </el-main>
    <add-goods ref="addGoodsDlg" @handleSave="handleAddGoods"></add-goods>
    <div id="print-view">
      <div class="print-view-main">
        <div class="paper-wrapper paper-a4"></div>
      </div>
      <trade-print ref="tradePrint" :print-view-tab.sync="print.type" :print-config.sync="print.config"></trade-print>
    </div>
  </el-container>
</template>

<script>
import { submit, exportFile } from "@/api/base";
import { baseURL, successCode } from "@/config";
import dayjs from "dayjs";
import clipboard from "@/utils/clipboard";
import AddGoods from "@/views/purchase/components/AddGoods.vue";
import TradePrint from '@/views/stall/trade/components/TradePrint.vue';
import WarehouseSelect from "@/views/components/common/WarehouseSelect.vue";
export default {
  name: "pageList",
  components: { AddGoods, TradePrint, WarehouseSelect },
  data() {
    return {
      routeParam: {},
      tableConfig: {
        url: "/api/wms/inventory/getList",
        rowKey: "InventoryID",
        columns: [
          {
            label: "盘点日期",
            prop: "RecordAddTime",
            type: "text",
            width: "180px",
          },
          {
            label: "盘点仓库",
            prop: "WName",
            type: "text",
          },
          {
            label: "盘点状态",
            prop: "StatusText",
            type: "text",
            width: "130px",
          },
          {
            label: "盘点结果",
            prop: "Result",
            type: "text",
          },
        ],
      },
      queryParam: {
        CheckFlag: undefined,
        dateRange: [],
        WID: 0,
      },
      detail: {
        loading: false,
        isUpdate: false,
        row: {
          InventoryID: 0,
          WID: 0,
          CheckFlag: 0,
        },
        rowDetails: [],
      },
      deleteLoading: false,
      addLoading: false,
      timeout: null,
      searchItems: [],
      newDetails: [],
      selectRows: [],
      saveLoading: false,
      checkLoading: false,
      xlsData: {
        loading: null,
        uploadUrl: `${baseURL}/api/wms/inventory/importInventoryDetail`,
        uploadOtherUrl: `${baseURL}/api/wms/inventory/importOtherInventoryDetail`,
        headers: {
          Authorization: "Bearer " + this.$store.getters["user/accessToken"],
        },
        isAll: false,
        isOther: false,
      },
      print: {
        type: 'a4',
        config: {
          a4: {
            "printType": 3,
            "title": "",
            "itemNoWidth": 80,
            "colorWidth": 50,
            "showSettlePrice": false,
            "orderBySkuCountForPrint": true,
            "fontSize": {
              "name": 15,
              "detail": 14,
              "statistics": 15,
              "price": 14
            }
          }
        },
      },
    };
  },
  mounted() {
    var that = this;
    that.handleQuery();
    that.$nextTick(async () => {
      that.$refs.tradePrint.init();
    });
  },
  methods: {
    async handleWarehouseUpdated() {
      var that = this;
      if (typeof (that.detail.row.WID) != 'undefined' && that.detail.row.WID > 0) {
        var specIds = [];
        that.detail.rowDetails.forEach(d => {
          if (typeof (d.SpecID) != 'undefined' && d.SpecID > 0) {
            specIds.push(d.SpecID);
          }
        });
        if (specIds.length > 0) {
          that.detail.loading = true;
          const { data } = await submit("/api/wms/allocate/getProductSpecStock", { WID: that.detail.row.WID, SpecIDs: specIds });
          that.detail.rowDetails.forEach(d => {
            let stock = 0;
            if (typeof (data[d.SpecID]) != 'undefined') {
              stock = data[d.SpecID];
            }
            d.OldStock = stock;
          });
          that.detail.loading = false;
        }
      }
      that.detail.isUpdate = true;
    },
    async handleCopyDetail(row) {
      var that = this;
      that.detail.loading = true;
      const { data } = await submit("/api/purchase/getProducts", {
        Keyword: row.ItemNo, GetSpecs: true, GetStock: true, FuzzySearch: false
      });
      that.searchItems = data;
      if (that.searchItems.length > 0) {
        that.detail.rowDetails[0].SpecName = row.SpecName;
        that.detail.rowDetails[0].ProductID = row.ProductID;
        that.detail.rowDetails[0].ItemNo = row.ItemNo;
        that.handleMatchItemNo(that.detail.rowDetails[0]);
      }
      that.detail.loading = false;
    },
    addNewDetails(row) {
      var that = this;
      if (row.ProductID <= 0) {
        that.$message({ type: "error", message: "请选择商品!" });
        return;
      }
      if (typeof (row.SpecID) == 'undefined') {
        that.$message({ type: "error", message: "请选择颜色及规格!" });
        return;
      }
      for (var i = 0; i < that.newDetails.length; i++) {
        var item = that.newDetails[i];
        if (row.SpecID != -1 && item.SpecID != row.SpecID) {//全部
          continue;
        }
        var mainId = that.detail.row == null ? 0 : that.detail.row.InventoryID;
        var has = that.detail.rowDetails.some(x => typeof (x._NewRow) == 'undefined' && x.ProductID == item.ProductID && x.SpecID == item.SpecID);
        if (!has) {
          that.detail.rowDetails.push({
            IDetailID: 0,
            InventoryID: mainId,
            ProductID: item.ProductID,
            SpecID: item.SpecID,
            SpecName: item.SpecName,
            ItemNo: row.ItemNo,
            OldStock: item.Stock,
            NewStock: row.NewStock,
            AddStock: row.NewStock - item.Stock
          });
        }
      }
      that.setRowDetails(that.detail.rowDetails);
      that.detail.isUpdate = true;
    },
    async querySearchItemNo(query, callback) {
      var that = this;
      if (query !== '') {
        clearTimeout(that.timeout);
        that.timeout = setTimeout(async () => {//加上延时，避免段时间输入太多而请求太多次
          const { data } = await submit("/api/purchase/getProducts", {
            Keyword: query, GetSpecs: true, GetStock: true
          });
          that.searchItems = data;
          if (callback != null) {
            callback(data);
          }
        }, 200);
      }
    },
    handleSeletedItemNo(selectedRow, row) {
      var that = this;
      var specs = selectedRow.Specs;
      that.newDetails = specs;
      row.ProductID = selectedRow.ProductID;
      row.ItemNo = selectedRow.ItemNo;
    },
    handleMatchItemNo(row) {
      var that = this;
      let selectedRow = that.searchItems.filter((i) => i.ItemNo.toLowerCase() == row.ItemNo.toLowerCase());
      if (typeof (row._NewRow) != 'undefined' && row._NewRow) {
        row.SpecID = undefined;
        row.SpecName = "";
      }
      if (selectedRow.length > 0) {
        that.handleSeletedItemNo(selectedRow[0], row);
      } else {
        that.newDetails = [];
      }
    },
    handleSelectText(e) {
      e.currentTarget.select();
    },
    handleDetailStockChange(row) {
      this.detail.isUpdate = true;
      row.AddStock = row.NewStock - row.OldStock;
    },
    checkRight(type) {
      var that = this;
      switch (type) {
        case "export":
          return that.detail.row != null;
        case "print":
          return that.detail.row != null && that.detail.row.CheckFlag == 1;
        case "import":
        case "check":
        case "delete":
          return that.detail.row != null && that.detail.row.CheckFlag == 0;
        case "save":
        case "editDetailStock":
        case "copy":
        case "deleteDetail":
        case "newDetail":
          return that.detail.row == null || (that.detail.row != null && that.detail.row.CheckFlag == 0);
      }
    },
    deleteDetail(index) {
      var that = this;
      that.detail.rowDetails.splice(index, 1);
      that.detail.isUpdate = true;
    },
    handleAdd() {
      var that = this;
      if (that.detail.isUpdate) {
        that.$confirm(`当前盘点单存在变动尚未保存，是否继续创建新的盘点单?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          that.removeEditData();
        }).catch(() => {
          //取消          
        });
      } else {
        that.removeEditData();
      }
    },
    deleteRow() {
      var that = this;
      if (that.detail.row == null || that.detail.row.InventoryID == 0) {
        return;
      }
      that.$confirm(`您是否要删除当前盘点单?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        that.deleteLoading = true;
        const result = await submit(`/api/wms/inventory/delete`, { InventoryIDs: that.detail.row.InventoryID });
        that.deleteLoading = false;
        if (result.success) {
          that.$message({
            type: "success",
            message: "删除成功!",
          });
          that.removeEditData();
          that.handleQuery();
        }
      }).catch(() => {
        //取消          
      });
    },
    reset() {
      this.queryParam.dateRange = [];
      this.queryParam.CheckFlag = undefined;
      this.queryParam.WID = undefined;
      this.handleQuery();
    },
    calcDetail(row) {
      var addStock = row.NewStock - row.OldStock;
      if (addStock > 0) {
        return '盘盈+' + addStock;
      } else if (addStock < 0) {
        return '盘亏' + addStock;
      } else {
        return '0';
      }
    },
    calcTotalCount(type) {
      var that = this;
      if (type == 'time') {
        return (that.detail.row == null || that.detail.row.InventoryID == 0) ? '新建' : that.detail.row.RecordAddTime;
      }
      else {
        var count = 0;
        for (var x = 0; x < that.detail.rowDetails.length; x++) {
          if (that.detail.rowDetails[x]._NewRow) continue;
          var typeValue = that.detail.rowDetails[x][type];
          count += typeValue;
        }
        return count;
      }
    },
    showAddGoodDlg() {
      this.$refs.addGoodsDlg.init(true, '新增盘点商品');
    },
    handleAddGoods(data) {
      var that = this;
      var mainId = that.detail.row == null ? 0 : that.detail.row.InventoryID;
      for (var color in data.Colors) {
        for (var i = 0; i < data.Colors[color].Details.length; i++) {
          var sizeDetail = data.Colors[color].Details[i];
          var has = that.detail.rowDetails.some(x => typeof (x._NewRow) == 'undefined' && x.ProductID == sizeDetail.ProductID && x.SpecID == sizeDetail.SpecID);
          if (!has) {
            that.detail.rowDetails.push({
              IDetailID: 0,
              InventoryID: mainId,
              ProductID: data.ProductID,
              SpecID: sizeDetail.SpecID,
              SpecName: color + '-' + sizeDetail.Size,
              ItemNo: data.ItemNo,
              OldStock: sizeDetail.Stock,
              NewStock: 0,
              AddStock: 0 - sizeDetail.Stock
            });
          }
        }
      }
      //排序
      that.setRowDetails(that.detail.rowDetails);
      that.detail.isUpdate = true;
    },
    batchSetNum() {
      var that = this;
      for (var i = 0; i < that.detail.rowDetails.length; i++) {
        if (that.detail.rowDetails[i]._NewRow) continue;
        that.detail.rowDetails[i].NewStock = that.detail.rowDetails[i].OldStock;
        that.detail.rowDetails[i].AddStock = 0;
      }
    },
    removeEditData() {
      this.detail.row = {
        InventoryID: 0,
        WID: 0,
        CheckFlag: 0,
      };
      this.setRowDetails([]);
      this.detail.isUpdate = false;
    },
    handleQuery(id = 0, isCurrentChange = false) {
      var that = this;
      that.$refs.gridTable.searchParam = { ...that.queryParam };
      if (that.queryParam.dateRange && that.queryParam.dateRange.length > 0) {
        that.$refs.gridTable.searchParam.TimeStart = that.queryParam.dateRange[0];
        that.$refs.gridTable.searchParam.TimeEnd = that.queryParam.dateRange[1];
      } else {
        that.$refs.gridTable.searchParam.TimeStart = '';
        that.$refs.gridTable.searchParam.TimeEnd = '';
      }
      delete that.$refs.gridTable.searchParam.dateRange;
      if (id > 0) {
        that.$refs.gridTable.fetchData(isCurrentChange, function (tableData) {
          var rows = tableData.filter(x => x.InventoryID == id);
          if (rows.length > 0) {
            that.$nextTick(() => {
              that.rowClick(rows[0]);
            });
          }
        });
      } else {
        that.$refs.gridTable.fetchData();
        that.removeEditData();
      }
    },
    handleSelectionChange(selection) {
      this.selectRows = selection;
      if (selection.length <= 0) {
        this.removeEditData();
      }
    },
    handleRowClick(row) {
      var that = this;
      if (that.detail.isUpdate) {
        that.$confirm(`当前盘点单存在变动尚未保存，点击确定将丢失变动过的数据，是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          that.rowClick(row);
        }).catch(() => {
          //取消          
        });
      } else {
        that.rowClick(row);
      }
    },
    async rowClick(row) {
      this.$refs.gridTable.$refs.gridTable.clearSelection();
      this.$refs.gridTable.$refs.gridTable.toggleRowSelection(row);
      this.detail.loading = true;//防止单个采购单太大或电脑性能问题导致太久没反应
      this.detail.row = JSON.parse(JSON.stringify(row));
      this.detail.row.Details = [];
      const result = await submit("/api/wms/inventory/getDetails", { InventoryID: row.InventoryID });
      if (result.success) {
        this.detail.row.Details = result.data;
      }
      this.setRowDetails(this.detail.row.Details);
      this.detail.loading = false;
    },
    setRowDetails(list) {
      var that = this;
      if (list && list.length > 0) {
        list = list.filter(x => x._NewRow == undefined || x._NewRow != true);
        list = list.sort(function (a, b) {
          if (a.ProductID !== b.ProductID) return a.ProductID < b.ProductID ? -1 : 1
          else if (a.SpecName !== b.SpecName) return a.SpecName < b.SpecName ? -1 : 1
        });
      }
      that.newDetails = [];
      if (that.detail.row != null && that.detail.row.CheckFlag == 1) {
        that.detail.rowDetails = list;
      } else {
        var newRow = {
          _NewRow: true,
          ProductID: 0,
          SpecID: undefined,
          SpecName: '',
          ItemNo: '',
          OldStock: 0,
          NewStock: 0,
          AddStock: 0
        };
        if (list && list.length > 0) {
          that.detail.rowDetails = [newRow, ...list];
        } else {
          that.detail.rowDetails = [newRow];
        }
      }
    },
    async handleSubmit(type) {
      var that = this;
      var param = {
        InventoryID: that.detail.row == null ? 0 : that.detail.row.InventoryID,
        WID: that.detail.row == null ? 0 : that.detail.row.WID,
        Type: type,
        Details: []
      };
      for (var i = 0; i < that.detail.rowDetails.length; i++) {
        var item = that.detail.rowDetails[i];
        if (typeof (item._NewRow) != 'undefined' && item._NewRow) continue;
        param.Details.push({
          ProductID: item.ProductID,
          SpecID: item.SpecID,
          NewStock: item.NewStock
        });
      }
      if (param.Type == 'save') {
        that.saveLoading = true;
      } else {
        that.checkLoading = true;
      }
      const result = await submit(`/api/wms/inventory/save`, param).catch((error) => {
        if (param.Type == 'save') {
          that.saveLoading = false;
        } else {
          that.checkLoading = false;
        }
      });
      if (param.Type == 'save') {
        that.saveLoading = false;
      } else {
        that.checkLoading = false;
      }
      if (result.success) {
        that.$message({
          type: "success",
          message: "操作成功!",
        });
        that.handleQuery(result.data, param.InventoryID > 0);
      }
    },
    async handleSave(type) {
      var that = this;
      //验证数据
      if (type == 'save') {
        if (that.detail.rowDetails.length <= 0) {
          that.$baseMessage(`请选择要盘点的商品`, "error");
          return;
        }
        that.handleSubmit(type);
      } else {
        var resultDetails = that.detail.rowDetails.filter(x => x._NewRow == undefined || x._NewRow != true);
        if (resultDetails.length <= 0) {
          that.$confirm('当前盘点单没有任何商品，提交将清空所有库存，确认提交生效当前盘点单?', "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(async () => {
            that.handleSubmit(type);
          }).catch(() => {
            //取消          
          });
        } else {
          that.$confirm('确认提交生效当前盘点单?', "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(async () => {
            that.handleSubmit(type);
          }).catch(() => {
            //取消          
          });
        }
      }
    },
    async handleExportOther(command) {
      var that = this;
      if (command == 'Export') {
        const today = dayjs().format('YYYYMMDDHHmmss');
        var excelName = '其他盘点模版_' + today + '.xls';
        await that.baseExport(excelName, '/api/wms/inventory/exportOtherTemplete');
      } else if (command == 'Import') {
        that.xlsData.isOther = true;
        that.$confirm('确认导入并创建盘点单?', "其他盘点", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(async () => {
          this.$refs['importOtherXls'].$refs['upload-inner'].handleClick();
        }).catch(() => {
          //取消          
        });
      }
    },
    async handleExportAll(command) {
      var that = this;
      if (command == 'Export') {
        const today = dayjs().format('YYYYMMDDHHmmss');
        var excelName = '盘前商品库存_' + today + '.xls';
        await that.baseExport(excelName, '/api/wms/inventory/export', { ExportProductStocks: true });
      } else if (command == 'Import') {
        that.xlsData.isAll = true;
        that.$confirm('确认导入并创建盘点单?', "全盘盘点", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(async () => {
          this.$refs['importXls'].$refs['upload-inner'].handleClick();
        }).catch(() => {
          //取消          
        });
      }
    },
    async handleExport() {
      var that = this;
      const today = dayjs(that.detail.row.RecordAddTime).format('YYYYMMDDHHmmss');
      var excelName = (that.detail.row.CheckFlag == 1 ? '' : '【未审】') + '盘点单_' + '_' + today + '.xls';
      await that.baseExport(excelName, '/api/wms/inventory/export', {
        InventoryID: that.detail.row.InventoryID,
      });
    },
    async baseExport(excelName, url, param) {
      const { data } = await exportFile(url, param);
      const blob = new Blob([data.Blob], { type: "application/vnd.ms-excel" });
      var a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = excelName;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
    uploadXlsSuccess(res) {
      if (successCode.includes(res.code)) {
        this.$message({ type: "success", message: res.msg });
      } else {
        this.$message({ type: "error", message: "导入错误：" + res.msg });
      }
      if (this.xlsData.isOther) {
        this.$refs.importOtherXls.clearFiles();
      } else {
        if (this.xlsData.isAll) {
          this.$refs.importXls.clearFiles();
        }
        else {
          this.$refs.uploadXls.clearFiles();
        }
      }
      this.xlsData.isAll = false;
      this.xlsData.isOther = false;
      if (this.xlsData.loading != null) {
        this.xlsData.loading.close();
      }
      this.handleQuery(res.data, true);
    },
    uploadXlsError() {
      this.xlsData.isAll = false;
      this.xlsData.isOther = false;
      this.$message({ type: "error", message: "导入失败" });
      if (this.xlsData.loading != null) {
        this.xlsData.loading.close();
      }
    },
    uploadXlsBefore(file) {
      this.xlsData.loading = this.$loading({
        lock: true,
        text: "正在导入中......"
      });
    },
    async handlePrint() {
      var that = this;
      var inventoryID = that.detail.row.InventoryID;
      const today = dayjs(that.detail.row.RecordAddTime).format('YYYYMMDDHHmmss');
      //that.print.config.a4.title = "盘点单_"+today;
      await that.$refs.tradePrint.getInventoryPrintInfo(inventoryID);
      if (that.$refs.tradePrint.printData.length > 0) {
        await this.$refs.tradePrint.printInventory();
        this.$('#print-view .paper-bill,#print-view .paper-tag').empty();
      }
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-aside {
    .el-select {
      width: 100px;
      margin-right: 5px;
    }

    .date-range {
      width: 210px;

      ::v-deep {
        .el-range-input {
          width: 70px;
        }
      }
    }

    .el-radio {
      margin-right: 10px;
    }

    .el-form-item__label {
      padding-right: 0;
    }
  }

  .el-main {
    .el-header {
      .span-spec-name {
        font-weight: bold;
        font-size: 20px;
      }

      .span-spec-count {
        margin-left: 20px;
        font-size: 16px;
      }

      .btn-set-num {
        float: right;
        margin-right: 225px;
      }
    }

    .el-main {

      .size-tag {
        height: 36px;
        line-height: 33px;
        margin: 6px 5px 3px 0px;
        float: left;

        .el-input--small .el-input__inner {
          height: 22px;
          line-height: 22px;
          padding: 0px;
          text-align: center;
        }
      }

      .tag-item:hover .icon-delete {
        display: block;
      }

      .span-delete {
        color: red;
        font-size: 12px;
        cursor: pointer;
        margin-left: 5px;
      }

      .span-copy {
        color: green;
        font-size: 12px;
        cursor: pointer;
        margin-left: 5px;
      }

      .span-delete:hover {
        text-decoration: underline;
      }

      .icon-delete {
        background-color: red;
        border-radius: 10px;
        color: #fff;
        opacity: 0.8;
        float: left;
        cursor: pointer;
        display: none;
        margin-left: -15px;
      }
    }

    .el-footer {
      vertical-align: middle;

      .el-button {
        height: 30px;
      }

      &>div {
        display: inline-block;
        width: 60%;

        &:last-child {
          text-align: right;
          width: 40%;

          .div-upload,
          .el-button {
            display: inline-block;
            margin-left: 5px;
          }
        }
      }
    }
  }
}


#print-view {
  position: fixed;
  left: -1000px;
}
</style>
